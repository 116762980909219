import React from 'react';
import { Loader } from 'lucide-react';
import { Text, Flex } from 'rebass/styled-components';
import CreateJobAlertPanel from '../subscribeJobAlertsCard/createJobAlertPanel';
import { NetworkSchema } from '../../../schema/network';
import { useJobAlerts } from '../../../hooks/useJobAlert';

export const CreateJobAlert = ({ network }) => {
  const { loading, subscribing, onSubmit, showJobFunctionSelector, jobFunctions, selectedFilters } = useJobAlerts(
    network,
    'toggle',
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <CreateJobAlertPanel
      onSubmit={onSubmit}
      showJobFunctions={showJobFunctionSelector}
      subscribing={subscribing}
      jobFunctions={jobFunctions.map(({ id, name }) => ({ value: id, label: name }))}
      sx={{
        px: [0, 0, 0],
      }}
      header={
        <Text width="100%" as="p" textAlign="left" mb="8px">
          Get daily alerts when new jobs match your current filters{selectedFilters ? <i>: {selectedFilters}</i> : '.'}
        </Text>
      }
      footer={
        showJobFunctionSelector ? (
          <Flex
            width="100%"
            p="0 16px"
            mt="24px"
            alignItems="center"
            sx={{ gap: '16px', backgroundColor: 'neutral.20' }}
            minHeight="56px"
          >
            <Text minWidth="24px" fontSize="24px">
              📩
            </Text>
            <Text as="p" fontWeight="500" color="text.main" fontSize="14px" lineHeight="1.4">
              Get the job you want - for more relevant jobs, refine your search before creating job alerts.
            </Text>
          </Flex>
        ) : null
      }
      buttonText="Subscribe"
      inModal
      description={`Be the first to know about opportunities in the ${network.name} network, including open jobs that match your search criteria.`}
    />
  );
};

CreateJobAlert.propTypes = {
  network: NetworkSchema.isRequired,
};
